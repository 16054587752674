<template>
  <el-dialog
    :title="lang_data == 1 ? '收货地址管理' : 'Delivery address management'"
    :visible="true"
    width="850px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div
      class="address-list"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <div class="address-item" v-for="item in addrList" :key="item.id">
        <div class="address-info">
          <b
            ><div>{{ item.accept_name }} {{ item.mobile }}</div>
            <div>
              {{ item.province.value }}{{ item.city.value
              }}{{ item.county.value }}{{ item.addr }}
            </div></b
          >
        </div>
        <div class="address-action">
          <div v-if="item.is_default.key == 1" class="mr10">
            <b>{{ lang_data == 1 ? "当前默认" : "The current default" }}</b>
          </div>
          <el-link type="primary" v-else @click="setDefault(item)" class="mr10">
            {{ lang_data == 1 ? "设为默认" : "Set to the default" }}
          </el-link>
          <el-link
            type="primary"
            @click="showUpdateAddressModal(item)"
            class="mr10"
          >
            {{ lang_data == 1 ? "编辑" : "Edit" }}
          </el-link>
          <el-link
            type="danger"
            @click="removeItem(item)"
            class="mr10"
            v-if="item.is_default.key != 1"
          >
            {{ lang_data == 1 ? "删除" : "Delete" }}
          </el-link>
        </div>
      </div>
      <div class="address-item address-add" @click="showUpdateAddressModal()">
        <div class="address-info">
          {{ lang_data == 1 ? "新增地址" : "The new address" }}
        </div>
      </div>

      <UpdateAddress
        v-if="updateAddressModal.show"
        :data="updateAddressModal.data"
        @close="updateAddressModal.show = false"
        @confirm="onUpdateAddress"
      ></UpdateAddress>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">{{
        lang_data == 1 ? "取消" : "Cancel"
      }}</el-button>
      <el-button size="small" type="primary" @click="handleConfirm">{{
        lang_data == 1 ? "确定" : "Confirm"
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import UpdateAddress from "./updateAddress.vue";
export default {
  components: {
    UpdateAddress,
  },
  props: {
    defaultSelectAddress: {
      type: Object,
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      addrList: [],
      form: {
        address: null,
      },
      updateAddressModal: {
        show: false,
        data: null,
      },
      loading: true,
    };
  },
  mounted() {
    this.getAddrList().then(() => {
      this.addrList.forEach((item) => {
        if (
          this.defaultSelectAddress &&
          this.defaultSelectAddress.id == item.id
        ) {
          this.form.address = item;
        } else {
          if (item.is_default.key == 1) {
            this.form.address = item;
          }
        }
      });
    });
  },
  methods: {
    getAddrList() {
      this.loading = true;
      return new Promise((resolve) => {
        this.$axios.get("/ucenter/getAllAddress").then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.addrList = res.data;
            resolve();
          }
          this.loading = false;
        });
      });
    },
    setDefault(item) {
      let params = {
        id: item.id,
        is_default: 1, //e.value ? 1 : -1,
        // is_default: !item._is_default ? 1 : -1
      };
      this.$axios.post("/ucenter/setDefault", params).then((res) => {
        if (res.code != 1) {
          // 保存失败时还原状态
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
          this.getAddrList();
        }
      });
    },
    removeItem(item) {
      this.$confirm(
        this.lang_data == 1
          ? "确认要删除该地址吗?"
          : "Are you sure you want to delete this address?",
        this.lang_data == 1 ? "提示" : "Prompt"
      )
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios.post("/ucenter/deladdress", params).then((res) => {
            if (res.code != 1) {
              this.$message.error(res.msg);
            } else {
              this.$message.success(res.msg);
              this.getAddrList();
            }
          });
        })
        .catch((err) => console.error(err));
    },
    showUpdateAddressModal(item) {
      this.updateAddressModal = {
        show: true,
        data: item,
      };
    },
    onUpdateAddress() {
      this.updateAddressModal.show = false;
      this.getAddrList();
    },
    handleClose() {
      this.$emit("close");
    },
    handleConfirm() {
      this.$emit("confirm", this.form.address);
    },
  },
};
</script>
<style lang="scss" scoped>
.address-list {
  min-height: 200px;
  .address-item {
    height: 64px;
    border: 1px solid #e1e1e5;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .address-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .address-action {
      display: flex;
      align-items: center;
    }

    &.address-add {
      background: #fafafa;
      font-weight: bold;
      cursor: pointer;
      font-size: 16px;
    }
  }
}
</style>