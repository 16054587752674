<template>
  <div class="integral-page-view">
    <div class="main-cont">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mt20 mb20">
        <el-breadcrumb-item :to="{ path: '/pointsMall' }">{{
          lang_data == 1 ? "积分商城" : "Integral mall"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            path: '/exchange',
            query: { goods_id: form.goods_id },
          }"
          >{{ lang_data == 1 ? "兑换" : "Exchange" }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          lang_data == 1 ? "确认订单" : "Make sure the order"
        }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div
        class="exchange-panel"
        v-loading="loading"
        :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
      >
        <div class="sub-title">
          <i class="el-icon-location-outline"></i>
          {{ lang_data == 1 ? "收货信息" : "Receiving information" }}

          <el-button
            size="small"
            round
            type="primary"
            class="addr-link"
            @click="addressModal.show = true"
          >
            {{ lang_data == 1 ? "地址管理" : "Address management"
            }}<i class="el-icon-arrow-right"></i>
          </el-button>
        </div>

        <div class="addr-list">
          <div
            class="addr-item"
            v-for="item in addrList"
            :key="item.id"
            :class="{
              default: item.is_default.key == 1,
              select: form.address && item.id == form.address.id,
            }"
            @click="form.address = item"
          >
            <div class="addr-status">
              {{
                item.is_default.key == 1
                  ? lang_data == 1
                    ? "默认"
                    : "Default"
                  : lang_data == 1
                  ? "普通"
                  : "Ordinary"
              }}
            </div>
            <div class="addr-detail">
              <b
                ><div>{{ item.accept_name }} {{ item.mobile }}</div>
                <div>
                  {{ item.province.value }}{{ item.city.value
                  }}{{ item.county.value }}{{ item.addr }}
                </div></b
              >
            </div>
          </div>
          <div class="addr-item addr-add" @click="showUpdateAddressModal()">
            新增地址
          </div>
        </div>

        <div class="sub-title" style="margin-top: 20px">
          <i class="el-icon-shopping-bag-1"></i>
          {{ lang_data == 1 ? "商品信息" : "Address management" }}
        </div>

        <div class="goods-box" v-if="detail">
          <div class="goods-avatar">
            <img
              v-if="detail.main_image_url"
              :src="detail.main_image_url"
              alt=""
            />
          </div>
          <div class="goods-info">
            <div class="goods-title">
              <b>{{ detail.name }} </b> <span>x {{ form.count }}</span>
            </div>
            <div class="goods-price">
              <img
                src="~@/assets/img/Points mall-icon-Points details.png"
                alt=""
              />
              <span style="margin-left: 4px; color: #409eff"
                ><b>{{ detail.integral }}</b></span
              >
              <span class="old-price">￥{{ detail.reference_price }}</span>
            </div>
          </div>
          <div class="goods-total">
            {{ lang_data == 1 ? "积分合计" : "Integral combined" }}：<span
              class="red-price"
              >{{ totalPrice }}</span
            >
          </div>
        </div>

        <div class="remark-group">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            :placeholder="lang_data == 1 ? '请输入商品备注' : 'Please Enter'"
          ></el-input>
        </div>

        <div class="action-group">
          <el-button
            class="submit-btn"
            type="primary"
            round
            @click="exchange()"
            >{{ lang_data == 1 ? "提交订单" : "Submit orders" }}</el-button
          >
        </div>
      </div>
    </div>

    <AddressModal
      v-if="addressModal.show"
      :default-select-address="form.address"
      @close="addressModal.show = false"
      @confirm="onAddressModalConfirm"
    ></AddressModal>
    <UpdateAddress
      v-if="updateAddressModal.show"
      :data="updateAddressModal.data"
      @close="updateAddressModal.show = false"
      @confirm="onUpdateAddress"
    ></UpdateAddress>
  </div>
</template>
<script>
import AddressModal from "./components/addressModal";
import UpdateAddress from "./components/updateAddress.vue";
export default {
  components: {
    AddressModal,
    UpdateAddress,
  },
  data() {
    return {
      detail: null,
      lang_data: localStorage.lang_data || 1,
      form: {
        goods_id: "",
        count: 1,
        address: null, // 当前选中的地址 未选择时默认是默认地址
        remark: "",
      },

      addrList: [],

      addressModal: {
        show: false,
      },

      updateAddressModal: {
        show: false,
        data: null,
      },
      loading:true
    };
  },
  computed: {
    totalPrice() {
      if (!this.detail) return 0;
      return this.form.count * this.detail.integral;
    },
  },
  mounted() {
    this.form.goods_id = this.$route.query.goods_id;
    this.form.count = this.$route.query.count;

    this.getAddrList().then(() => {
      this.addrList.forEach((item) => {
        if (item.is_default.key == 1) {
          this.form.address = item;
        }
      });
    });
    this.getDetail();
  },
  methods: {
    getAddrList() {
      return new Promise((resolve) => {
        this.$axios.get("/ucenter/getAllAddress").then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.addrList = res.data;
            resolve();
          }
        });
      });
    },
    showUpdateAddressModal(item) {
      this.updateAddressModal = {
        show: true,
        data: item,
      };
    },
    onUpdateAddress() {
      this.updateAddressModal.show = false;
      this.getAddrList();
    },
    getDetail() {
      this.loading = true;
      let params = {
        id: this.$route.query.goods_id,
      };
      this.$axios
        .get("/ucenter/integral_goods/getInfo", { params })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
          } else {
            this.detail = res.data;
          }
          this.loading = false;
        });
    },
    onAddressModalConfirm(address) {
      this.addressModal.show = false;
      this.getAddrList().then(() => {
        this.addrList.forEach((item) => {
          if (item.id == address.id) {
            return (this.form.address = item);
          } else if (item.is_default.key == 1) {
            return (this.form.address = item);
          }
        });
      });
    },
    exchange() {
      if (!this.form.address) {
        return this.$message.error(
          this.lang_data == 1
            ? "请选择收货地址"
            : "Please create a shipping address"
        );
      } else {
        this.$confirm(
          this.lang_data == 1
            ? "请确认收货信息填写无误，订单提交后将无法修改"
            : "Please confirm that the receipt information is correct. The order cannot be modified after submission",
          this.lang_data == 1 ? "订单提交确认" : "Order submission confirmation"
        )
          .then(() => {
            console.log(this.form.address);

            this.$axios
              .post("/ucenter/exchange_integral_goods/exchange", {
                id: this.$route.query.goods_id,
                exchange_num: this.form.count,
                address_id: this.form.address.id,
                remark: this.form.remark,
              })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  // 提交订单成功后跳转页面
                  this.$router.back();
                } else {
                  this.$message.error(res.msg);
                }
              });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.integral-page-view {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  height: calc(100% - 70px);
  overflow: auto;
  .main-cont {
    width: 1300px;
    flex: 1;
    margin: 0 auto;
    padding-bottom: 30px;

    .exchange-panel {
      width: 1300px;
      background: #ffffff;
      box-shadow: 0 0 12px 0#e4e4e4;
      border: 1px solid #e4e4e4;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;

      .sub-title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        position: relative;

        .addr-link {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      .addr-list {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        max-height: 350px;
        overflow: auto;
        .addr-item {
          width: 420px;
          height: 64px;
          border: 1px solid #e1e1e5;
          margin: 0 10px 10px 0;
          cursor: pointer;
          padding: 12px;
          box-sizing: border-box;
          display: flex;
          flex-shrink: 0;
          overflow: hidden;
          position: relative;

          &:hover {
            background: #eef7ff;
          }
          .addr-status {
            display: inline-block;
            padding: 3px 6px;
            flex-shrink: 0;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #f2f2f2;
            border-radius: 4px;
            font-size: 12px;
          }
          .addr-detail {
            margin-left: 8px;
            font-size: 14px;
            color: #606163;
          }
          &.addr-add {
            background: #fafafa;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              background: #efefef;
            }
          }

          &.select {
            border: 1px solid #409eff;
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              background: url("~@/assets/img/check-triangle.png");
            }

            .addr-status {
              background: #409eff;
              color: #ffffff;
            }
          }
        }
      }

      .goods-box {
        margin-top: 10px;
        width: 100%;
        height: 96px;
        border-radius: 4px;
        border: 1px solid #e1e1e5;
        display: flex;
        align-items: center;
        padding: 0 20px 0 12px;
        box-sizing: border-box;
        .goods-avatar {
          width: 72px;
          height: 72px;
          background: #eee;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .goods-info {
          margin-left: 15px;
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .goods-title {
            width: 600px;
            b {
              font-size: 16px;
            }
          }

          .goods-price {
            font-weight: bold;
            color: #333;
            display: flex;
            align-items: center;
            .old-price {
              color: #b0b1b5;
              text-decoration: line-through;
              margin-left: 14px;
            }
          }
        }

        .goods-total {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #333;
          .red-price {
            font-size: 20px;
            font-weight: bold;
            color: #409eff;
          }
        }
      }
      .remark-group {
        margin-top: 20px;
      }

      .action-group {
        margin-top: 20px;
        text-align: right;
        .submit-btn {
          width: 168px;
        }
      }
    }
  }
}
</style>