import { render, staticRenderFns } from "./updateAddress.vue?vue&type=template&id=30dc5037&scoped=true&"
import script from "./updateAddress.vue?vue&type=script&lang=js&"
export * from "./updateAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30dc5037",
  null
  
)

export default component.exports