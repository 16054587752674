<template>
  <el-dialog
    :title="
      isEdit
        ? lang_data == 1
          ? '编辑收货地址'
          : 'Edit shipping address'
        : lang_data == 1
        ? '新增收货地址'
        : 'Add shipping address'
    "
    :visible="true"
    width="650px"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form label-width="80px" size="small">
      <el-form-item :label="lang_data == 1 ? '收货人' : 'Consignee'" required>
        <el-input
          style="width: 300px"
          v-model="form.accept_name"
          :placeholder="lang_data == 1 ? '请输入收货人名称' : 'Please Enter'"
        ></el-input>
      </el-form-item>
      <el-form-item :label="lang_data == 1 ? '联系电话' : 'Phone'" required>
        <el-input
          style="width: 300px"
          v-model="form.mobile"
          :placeholder="
            lang_data == 1 ? '请输入收货人联系电话' : 'Please Enter'
          "
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item :label="lang_data == 1 ? '省市区' : 'Provinces'" required>
        <RegionSelect v-model="form.area"></RegionSelect>
      </el-form-item>
      <el-form-item :label="lang_data == 1 ? '地址' : 'Address'" required>
        <el-input
          type="textarea"
          v-model="form.addr"
          :placeholder="lang_data == 1 ? '请输入收货地址' : 'Please Enter'"
        ></el-input>
      </el-form-item>
      <el-form-item :label="lang_data == 1 ? '设为默认' : 'Set to the default'">
        <el-switch
          v-model="form.is_default"
          :active-value="1"
          :inactive-value="-1"
        ></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">{{
        lang_data == 1 ? "取消" : "Cancel"
      }}</el-button>
      <el-button size="small" type="primary" @click="handleConfirm">{{
        lang_data == 1 ? "确定" : "Confirm"
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import RegionSelect from "./regionSelect.vue";
export default {
  components: {
    RegionSelect,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      addrList: [],
      form: {
        name: "",
        mobile: "",
        area: {
          province: "",
          city: "",
          county: "",
        },
        addr: "",
        is_default: 1,
      },
    };
  },
  computed: {
    isEdit() {
      return !!(this.data && this.data.id);
    },
  },
  mounted() {
    if (this.isEdit) {
      this.form = {
        accept_name: this.data.accept_name,
        mobile: this.data.mobile,
        area: {
          province: this.data.province.key,
          city: this.data.city.key,
          county: this.data.county.key,
        },

        addr: this.data.addr,
        is_default: this.data.is_default.key,
      };
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    ruleParams() {
      const phone =
        /^((13[0-9])|(14[4-9])|(15[0-3,5-9])|(17[0-8])|(18[0-9])|(16[2,5-7])|(19[0-9]))\d{8}$/;
      if (!this.form.accept_name) {
        this.$message.warning(
          this.lang_data == 1 ? "请输入姓名" : "Please enter your name"
        );
        return false;
      } else if (!(this.form.mobile + "").length) {
        this.$message.warning(
          this.lang_data == 1
            ? "请输入手机号"
            : "Please enter your mobile number"
        );
        return false;
      } else if (!phone.test(this.form.mobile)) {
        this.$message.warning(
          this.lang_data == 1
            ? "请输入正确的手机号码"
            : "Please enter the correct cell phone number"
        );
        return false;
      } else if (
        !this.form.area.province ||
        !this.form.area.city ||
        !this.form.area.county
      ) {
        this.$message.warning(
          this.lang_data == 1
            ? "请选择省市区"
            : "Please select a province or region"
        );
        return false;
      } else if (!this.form.addr.length) {
        this.$message.warning(
          this.lang_data == 1
            ? "请输入详细地址"
            : "Please enter address details"
        );
        return false;
      }
      return true;
    },
    handleConfirm() {
      let url = "/ucenter/addAddress";
      if (!this.ruleParams()) return;
      let params = {
        accept_name: this.form.accept_name, //收货人姓名
        province: this.form.area.province,
        city: this.form.area.city,
        county: this.form.area.county,
        addr: this.form.addr,
        mobile: this.form.mobile,
        is_default: this.form.is_default ? 1 : -1, // 1是 ， 0否
      };
      if (this.isEdit) {
        url = "/ucenter/editAddress";
        params.id = this.data.id;
      }
      this.$axios.post(url, params).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.$emit("confirm");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>